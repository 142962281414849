import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/whittlesey_church_story_large.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(
                    process.env.SIGNUP_URL,
                  );
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Customer story: How Camelo helps Whittlesey Christian Church manage an enthusiastic team"
          description="Find out how the Camelo employee scheduling app transforms scheduling, event organization, & communication for Whittlesey Christian Church."
        ></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Camelo helps Whittlesey Christian Church manage an
                    enthusiastic team
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section bg-image py-28 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
          `}
        />

        <div className="pb-2 pb-lg-9 pt-13 pt-lg-24 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link to="https://www.facebook.com/WhittleseyChristianChurch/" target="__blank">
                      {`Whittlesey Christian Church `}
                    </Link>
                    is based in the beautiful historic building on Broad Street,
                    Whittlesey, UK. As the church holds a variety of activities
                    for the community, keeping track of rotas and keeping people
                    updated was challenging for Richard Bibby, the church
                    leader. After discovering Camelo, he and his team have
                    gained peace of mind knowing they have something in place
                    that meets their needs
                  </p>
                  <h4 className="pt-8 pb-4">
                    How Whittlesey Christian Church began and their vision
                  </h4>
                  <p className="gr-text-8 text-black">
                    Whittlesey Christian Church first began operating as a
                    charity and governed by a trust deed in 1979. The vision of
                    the church is simply to reach people from the North, South,
                    East, and West with the message of the Christian gospel.
                  </p>
                  <p className="gr-text-8 text-black">
                    A vision of the fires propagating across a map of Europe was
                    given to the former minister in 1977, with sparks from one
                    fire igniting other fires. It was a vivid picture of the
                    message of the good news of reconciliation with God
                    spreading like a fire. It's an exciting vision which is
                    coming to pass in these days.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Physical rotas and communication were cumbersome
                  </h4>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “I'm still finding my way managing the team, who are
                      extremely supportive and enthusiastic.”—Richard shared
                    </p>
                  </blockquote>

                  <p className="gr-text-8 text-black">
                    The church hosts midweek events such as a community social
                    get-together with refreshments, which is very popular with
                    50-60 attending regularly. The local table tennis club is
                    hiring the premises with three to four sessions weekly. Arts
                    courses are also held on the premises from time to time, run
                    by a Cambridge-based charity organisation.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “A challenge has been keeping track of the who, what,
                      which, when, and where. Who's doing what, when and which
                      parts of the building are being used, where team meetings
                      are being held, and where I am planning to be.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    Before using Camelo, the church used paper and pen to manage
                    rotas. It’s a challenge for them to keep track of physical
                    rotas and remember to tell people. Inevitably, if plans are
                    changed, misunderstandings happen. As other leaders like to
                    schedule their teams too, mistakes while scheduling
                    happened.
                  </p>
                  <p className="gr-text-8 text-black">
                    They realized the need for finding a better system for
                    scheduling and communicating with people.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Discovering how convenient scheduling can become
                  </h4>
                  <p className="gr-text-8 text-black">
                    Richard found out about Camelo while browsing on AppStore
                  </p>
                  <p className="gr-text-8 text-black">
                    At first, it took trial and error for him to get the mix of
                    schedules and locations in place on the app. But he was
                    impressed with the flexibility of Camelo. The google
                    calendar export is a big benefit to him, as it pulls all of
                    the schedules together into a single view.
                  </p>
                  <p className="gr-text-8 text-black">
                    Once he got hold of how the app works, he started to see how
                    convenient scheduling has become. He started to feel the
                    peace of mind that something is in place and meets their
                    needs.
                  </p>

                  <h4 className="pt-8 pb-4">
                    How Camelo helps the Whittlesey Christian Church
                  </h4>
                  <p className="gr-text-8 text-black">
                    Richard and his team love the web app and open shift feature
                    on Camelo.
                  </p>
                  <p className="gr-text-8 text-black">
                    It’s easy to assign and re-assign people using the
                    drag-and-drop scheduler on the web app.
                  </p>
                  <p className="gr-text-8 text-black">
                    <blockquote className="blockquote mb-4 card-body">
                      <p className="text-black pt-4 gr-text-6 lead">
                        “The web interface makes it very easy to quickly
                        re-assign roles between people with drag and drop.“
                      </p>
                    </blockquote>
                  </p>
                  <p className="gr-text-8 text-black">
                    Team leaders can also add shifts using the smartphone app if
                    they don’t have access to a computer. When asked if he would
                    recommend Camelo to other people, Richard answered, “Yes,
                    because of the combination of app and web interface.”
                  </p>

                  <p className="gr-text-8 text-black">
                    The open shift feature, which allows team members to claim
                    shifts they want to take and the leader can confirm shift
                    claims afterward, also fits a non-profit organization well.
                  </p>
                  <p className="gr-text-8 text-black">
                    <blockquote className="blockquote mb-4 card-body">
                      <p className="text-black pt-4 gr-text-6 lead">
                        “The open shift feature and the ability for the owner to
                        confirm, which I requested and which was quickly
                        implemented, make it easy to manage a pull preferred
                        schedule, which is better for a non-profit organisation,
                        but using the push as needed.”
                      </p>
                    </blockquote>
                  </p>

                  <h4 className="pt-8 pb-4">Focusing on what truly matters</h4>
                  <p className="gr-text-8 text-black">
                    With its user-friendly interface and intuitive features,
                    Camelo has become an indispensable tool for Whittlesey
                    Church. The convenient tools for scheduling have saved them
                    time and brought immense peace of mind.
                  </p>
                  <p className="gr-text-8 text-black">
                    They can now focus on what truly matters: running an
                    enthusiastic team, serving the community, and spreading the
                    message of faith.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “The app gives us the peace of mind that something is in
                      place and meets our needs. I am slowly learning to work
                      with people better with the help from Camelo.”
                    </p>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
